import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Themed } from "theme-ui"
import Layout from "../components/interactive-space/layout"
import Form from "../components/interactive-space/form"
import BgLeft from "../images/bg-formulario.png"

const Formulario = ({ data }) => {

  const dataFragments = data.dataJson

  return(
    <Layout
      bgImageLeft={BgLeft}
    >
      <Container
        as={`main`}
        sx={{
          width: `100%`,
          minHeight: `100vh`,
          paddingTop: 3,
          paddingRight: [0, 0, 0, 4, 4, 4],
          paddingLeft: [0, 0, 0, 4, 4, 4],
          h1: {
            color: `gray.6`,
            fontSize: 6,
          },
          "p, ul, li, a, label": {
            color: `gray.6`,
          }
        }}
      >
        <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_2}} />
        <Form />
        <Themed.p><Themed.a as={Link} to={`/${dataFragments.fragment_3}`}>&#8592; {dataFragments.fragment_4}</Themed.a></Themed.p>
      </Container>
    </Layout>
  )

}

export default Formulario

export const query = graphql`
  query Formulario {
    dataJson(slug: {eq: "page-formulario"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
    }
  }
`