import React from "react"
import { Grid, Themed } from "theme-ui"
import Header from "./header"
import Footer from "./footer"

const Layout = ( props ) => {

  const bgImage = ( props.bgImage === "false" ) ? `none` : `url(${props.bgImageLeft}), url(${props.bgImageRight})`

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          backgroundColor: `white`,
          backgroundImage: `${bgImage}`,
          backgroundPosition: `bottom left, bottom right`,
          backgroundRepeat: `no-repeat, no-repeat`,
          backgroundSize: [ `150px auto, 150px auto`,
                            `200px auto, 200px auto`,
                            `200px auto, 200px auto`,
                            `400px auto, 400px auto`,
                            `400px auto, 400px auto`,
                            `400px auto, 400px auto`,
                          ],
          gridTemplateColumns: `auto`,
          gridTemplateRows: `auto`,
          gridTemplateAreas:[ `"header" "main" "footer"`, 
                              `"header" "main" "footer"`, 
                              `"header" "main" "footer"`, 
                              `"header" "main" "footer"`, 
                              `"header" "main" "footer"`,
                              `"header" "main" "footer"`,
                            ],
          height: [`auto`, `auto`, `100vh`, `100vh`, `100vh`, `100vh`],
          overflow: [`auto`, `auto`, `auto`, `hidden`, `hidden`, `hidden`],
          width: `100vw`,
          header: {
            gridArea: `header`,
            px: 4,
            zIndex: 1,
          },
          main: {
            gridArea: `main`,
          },
          footer: {
            gridArea: `footer`,
          }
        }}
      >
        <Header />
        {props.children}
        <Footer />
      </Grid>
    </Themed.root>
  )

}

export default Layout